import React from 'react';
import Layout from '../layouts/Layout';
// @ts-ignore
import back from '../images/back.svg';
const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			text={pageContext.title}
			version={2}
			title={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'}
			desc={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'}
		>
			<div className={'container mx-auto px-5 pt-6'}>
				<a href={'/search'} className={'flex flex-row text-sl-blue'}>
					<img className={'pr-2'} src={back} alt={'back'} />
					Back
				</a>
			</div>
			<div className={'container mx-auto px-5 py-6'}>
				<span
					className={
						'font-encode text-[40px] font-normal text-sl-yellow'
					}
				>
					Full job description
				</span>
				<div className={'flex flex-col-reverse py-6 lg:flex-row'}>
					<div className={'flex flex-col pr-8 lg:w-3/4'}>
						<p
							dangerouslySetInnerHTML={{
								__html: pageContext.description
							}}
							className="job-description-content"
						/>
						<a
							className="ApplyButton ApplyButtonBottom dn d-f-xl mt-6 w-52 bg-sl-blue px-8 py-4 text-center text-white hover:text-white"
							href={pageContext.url}
							target={'_blank'}
						>
							<button>APPLY NOW</button>
						</a>
					</div>
					<div className={'flex flex-col max-lg:pb-10 lg:w-1/4'}>
						<div
							className={
								'h-82 flex flex-col border-t-8 border-sl-yellow bg-[#F4F4F4] p-8'
							}
						>
							<span
								className={'mb-3 font-encode text-lg font-bold'}
							>
								LOCATION
							</span>
							{pageContext.city}, {pageContext.state}
							<span
								className={
									'mb-3 mt-6 font-encode text-lg font-bold'
								}
							>
								REQ NUMBER
							</span>
							{pageContext.reqNumber}
							{pageContext.pay && (
								<>
									<span
										className={
											'mb-3 mt-6 font-encode text-lg font-bold'
										}
									>
										SALARY
									</span>
									{pageContext.pay}
								</>
							)}
							{pageContext.addtlcompinfo && (
								<span className="pt-2">
									{pageContext.addtlcompinfo}
								</span>
							)}
							{pageContext.benefits && (
								<>
									<span
										className={
											'mb-3 mt-6 font-encode text-lg font-bold'
										}
									>
										BENEFITS
									</span>
									{pageContext.benefits}
								</>
							)}
							<a
								className="ApplyButton ApplyButtonTop dn d-f-xl mt-6 bg-sl-blue px-8 py-4 text-center text-white hover:text-white max-lg:max-w-[200px]"
								href={pageContext.url}
								target={'_blank'}
							>
								<button>APPLY NOW</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default JobDescription;
